<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增轮播图</el-button>
          <el-input size="medium" v-model="searchData.name" placeholder="请输入轮播图名称" class="marginLeft10 width200"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询
          </el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">
            重置
          </el-button>
        </div>
      </template>

      <template slot="state" slot-scope="{ row }">
        <el-switch v-model="row.state" :inactive-value="2" :active-value="1"
                   @change="handleSwitch($event,row.id)"></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '新增'}轮播图`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 :upload-error="uploadError"
                 :upload-delete="uploadDelete"
                 :upload-after="uploadAfter"
                 :upload-before="uploadBefore"
                 @submit="submit">
        <template slot="linkType">
          <el-select v-model="form.linkType" @change="changeLinkType" clearable>
            <el-option label="新房" :value="1"></el-option>
            <el-option label="二手房" :value="2"></el-option>
            <el-option label="办公租赁" :value="3"></el-option>
            <el-option label="蜜豆商城" :value="4"></el-option>
            <el-option label="招募经纪人" :value="5"></el-option>
            <el-option label="外部链接" :value="6"></el-option>
          </el-select>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  listNew,
  listSecond,
  listTenancy,
  listGoods,
  add,
  update,
  view,
  updateNew,
  deleteNew
} from '@/api/base/banner'

export default {
  name: "index",
  data() {
    return {
      value: '',
      searchData: {
        name: ''
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      dialogVisible: false,
      form: {
        id: '',
        title: '',
        linkType: '',
        linkId: '',
        sort: '',
        state: '',
        image: ''
      },
      linkList: [],
      option: {
        title: '轮播图设置',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '标题',
            prop: 'title'
          },
          {
            label: '轮播图',
            prop: 'image',
            type: 'img'
          },
          {
            label: '排序值',
            prop: 'sort'
          },
          {
            label: '是否上架',
            prop: 'state'
          }
        ]
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '标题',
            prop: 'title',
            maxlength: 15,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入标题",
              trigger: "blur"
            }]
          },
          {
            label: '链接',
            prop: 'linkType',
            span: 12
          },
          {
            label: '',
            labelWidth: 0,
            prop: 'linkId',
            type: 'select',
            allowCreate: true,
            filterable: true,
            span: 12,
            dicData: [],
            display: false,
            props: {
              label: 'title',
              value: 'id'
            },
            rules: [{
              required: true,
              message: "请选择跳转链接",
              trigger: "blur"
            }]
          },
          {
            label: '外部链接',
            prop: 'url',
            span: 24,
            maxlength: 100,
            showWordLimit: true,
            rules: [{
              required: true,
              message: "请输入外部链接",
              trigger: "blur"
            }],
            display: false
          },
          {
            label: '排序值',
            prop: 'sort',
            type: 'number',
            span: 24,
            maxRows: 999999,
            minRows: 1,
            rules: [{
              required: true,
              message: "请输入排序值",
              trigger: "blur"
            }]
          },
          {
            label: '是否显示',
            prop: 'state',
            type: 'select',
            span: 24,
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 2
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否显示",
              trigger: "blur"
            }]
          },
          {
            label: '轮播图',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：750*360,最大20M',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传轮播图",
              trigger: "blur"
            }]
          }
        ]
      },
      listNewData: [],
      listSecondData: [],
      listTenancyData: [],
      listGoodsData: []
    }
  },
  async mounted() {
    await this.getAll();
  },
  methods: {

    getAll() {
      listNew().then(res => {
        this.listNewData = res.data;
      })
      listSecond().then(res => {
        this.listSecondData = res.data;
      })
      listTenancy().then(res => {
        this.listTenancyData = res.data;
      })
      listGoods().then(res => {
        this.listGoodsData = res.data;
      })
    },
    // 数据
    onLoad() {
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 新增轮播图
    handleCreate() {
      // console.log('新增轮播图')
      this.dialogVisible = true;
    },
    // 上下架
    handleSwitch(e, id) {
      update({
        id: id,
        state: e
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功');
        } else {
          this.$message.warning(res.msg);
        }
        this.onLoad();
      })
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑')
      const {data} = await view({
        id: row.id
      })
      // console.log(data)
      const {linkType, ...rest} = data;
      this.changeLinkType(linkType === 0 ? '' : linkType)
      this.form = {
        ...rest,
        linkType: linkType === 0 ? '' : linkType,
      }
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      // console.log('删除')
      this.$confirm('是否删除此轮播图?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功');
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 选择链接类型
    changeLinkType(val) {
      // console.log(val)
      const linkId = this.findObject(this.formOption.column, 'linkId');
      const url = this.findObject(this.formOption.column, 'url');
      if ( val && val < 5 ) {
        linkId.display = true;
        this.form.linkId = '';
        if (val === 1) {
          linkId.dicData = this.listNewData
        } else if (val === 2) {
          linkId.dicData = this.listSecondData
        } else if (val === 3) {
          linkId.dicData = this.listTenancyData
        } else if (val === 4) {
          linkId.dicData = this.listGoodsData
        }
        url.display = false;
        this.form.url = '';
      }else if( val && val === 6 ){
        linkId.display = false;
        this.form.linkId = '';

        url.display = true;
        this.form.url = '';
      } else {
        linkId.display = false;
        this.form.linkId = '';

        url.display = false;
        this.form.url = '';
      }
    },
    // 提交form
    submit(form, done) {
      // console.log(form)
      if (form.id) {
        updateNew(form).then(res => {
          if (res.code === 200) {
            this.$message.success('修改成功');
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm();
            }, 500)
            this.onLoad();
          } else {
            this.$message.error(res.msg);
            done();
          }
        }).catch(()=> done())
      } else {
        add(form).then(res => {
          if (res.code === 200) {
            this.$message.success('新增成功');
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm();
            }, 500)
            this.onLoad();
          } else {
            this.$message.warning(res.msg);
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500)
    },
    resetForm() {
      // 重置form
      this.form = {
        id: '',
        title: '',
        linkType: '',
        linkId: '',
        sort: '',
        state: '',
        image: ''
      }
      const linkId = this.findObject(this.formOption.column, 'linkId');
      linkId.display = false;
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)) {
        this.$message.error('文件格式有误');
        loading()
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done()
      if (!res.path) {
        let msg = JSON.parse(JSON.stringify(res)).message;
        if (msg.indexOf('401') > -1) {
          this.$message.error('登录失效，请重新登录');
          this.$router.push('/login');
        } else {
          this.$message.error('上传失败');
        }
        loading()
      } else {
        column.propsHttp.name = res.path;
      }
    },
  }
}
</script>

<style scoped>

</style>